import React, { useEffect, useRef, useState } from 'react'
import DateInput from './DateInput'
import PlanTable from './PlanTable'
import { formatDate } from '../util/format'
import Button from './Button'
import Modal from './Modal'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { CreatePlan, UpdatePlan } from '../graphql/mutations'
import { PlanCreated, PlansUpdated } from '../graphql/subscriptions'
import PlanningItemModal from './PlanningItemModal'

const includeFields = [
    {
        value: 'ORDERNO',
        label: 'Rida number',
    },
    {
        value: 'ORDER_DATE',
        label: 'Tellimuse kuup.',
    },
    {
        value: 'PRODUCTION_CODE',
        label: 'Toote kood',
    },
    {
        value: 'NAME',
        label: 'Klient',
    },
    {
        value: 'TELLIMUSNO',
        label: 'Tellimus',
    },
    {
        value: 'KLIENT_BOXCODE',
        label: 'Kliendi tootekood',
    },
    {
        value: 'MAT_ORDER_ID',
        label: 'Mat. tellimus',
    },
    {
        value: 'MOOT',
        label: 'Mõõt',
    },
    {
        value: 'MATERIAL',
        label: 'Materjal',
    },
    {
        value: 'MAT_LOADED',
        label: 'Mat. laaditud',
    },
    {
        value: 'KOGUS',
        label: 'Kogus',
    },
    {
        value: 'FACTORY_NAME',
        label: 'Tehas',
    },
    {
        value: 'SIZE',
        label: 'LxB',
    },
    {
        value: 'MAT_AMOUNT',
        label: 'LxB kogus',
    },
    {
        value: 'MAT_NUUT',
        label: 'Nuudid',
    },
    {
        value: 'MAT_DELIVERY',
        label: 'Mat. tarneaeg',
    },
    {
        value: 'ARRIVED_AMOUNT',
        label: 'Saabunud',
    },
    {
        value: 'DIFF_AMOUNT',
        label: 'Vahe',
    },
    {
        value: 'BOX',
        label: 'FEFCO',
    },
    {
        value: 'TRYKK',
        label: 'Trükk',
    },
    {
        value: 'DEADLINE',
        label: 'Tähtaeg',
    },
    {
        value: 'NEW_DEADLINE',
        label: 'Reaalne tähtaeg',
    },
    {
        value: 'START_DATE',
        label: 'Tootmise algus',
    },
    {
        value: 'FIN_DATE',
        label: 'Tootmise lõpp',
    },
    {
        value: 'ALUSEID',
        label: 'Aluseid',
    },
    {
        value: 'ALUS_MOOT',
        label: 'Aluse mõõt',
    },
    {
        value: 'PREMAID',
        label: 'Valmistoodang',
    },
    {
        value: 'STOCK_MAT',
        label: 'Laomaterjal',
    },
    {
        value: 'STOCK_MOOT',
        label: 'Mat. mõõt',
    },
]

const handleUpdateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.plansUpdated

    console.log('handleUpdateSubscription', newData)

    if (!newData) return prev

    const updated = prev.planningQuery.map((plan) => {
        if (!plan?.ITEMS?.length) return plan

        const updatedItems = plan.ITEMS.map(j => {
            if (j.PLANNING_ID === newData.PLANNING_ID) return {
                ...j,
                ...newData,
            }

            return j
        })

        return {
            ...plan,
            ITEMS: updatedItems,
        }
    })


    return Object.assign({}, prev, {
        planningQuery: updated,
    })
}

const handlePlanCreateSubscription = (prev, { subscriptionData }) => {
    const newData = subscriptionData?.data?.planCreated

    if (!newData) return prev

    let added = false
    const machineId = newData?.MACHINE_ID || null

    if (!machineId) return prev

    const updated = prev.planningQuery.map((plan) => {
        if (plan.DATE === newData.DATE && plan.MACHINE_ID === machineId) {
            added = true

            return {
                ...plan,
                ITEMS: [...plan.ITEMS, newData],
            }
        }

        return plan
    })

    if (!added && !prev?.planningQuery?.some(x => x.DATE === newData.DATE)) {
        updated.push({
            DATE: newData.DATE,
            ITEMS: [newData],
        })
    }

    return Object.assign({}, prev, {
        planningQuery: updated,
    })
}

const subscriptions = [
    // {
    //     document: PlansUpdated,
    //     updateQuery: handleUpdateSubscription,
    // },
    {
        document: PlanCreated,
        updateQuery: handlePlanCreateSubscription,
    },
]

const fieldConditions = (row, field) => {
    switch (field) {
        case 'ORDER_DATE':
        case 'FIN_DATE':
        case 'START_DATE':
        case 'DEADLINE':
        case 'NEW_DEADLINE':
        case 'MAT_DELIVERY':
            return formatDate(new Date(row[field]))
        case 'materialOrderData.order':
            return row?.materialOrderData?.order
        case 'materialOrderData.factory':
            return row?.materialOrderData?.factory
        case 'materialOrderData.size':
            return row?.materialOrderData?.size
        case 'materialOrderData.count':
            return row?.materialOrderData?.count
        case 'materialOrderData.amount':
            return row?.materialOrderData?.amount
        case 'materialOrderData.nuut':
            return row?.materialOrderData?.nuut
        case 'materialOrderData.delivery':
            return formatDate(new Date(row?.materialOrderData?.delivery))
        case 'materialOrderData.arrived':
            return row?.materialOrderData?.arrived
        case 'materialOrderData.diff':
            return row?.materialOrderData?.diff
        case 'client.NAME':
            return row?.client?.NAME
    }
}

const InitialModalSettings = {
    DATE: null,
    COMMENT: '',
}

const PlanningMachine = ({
    data,
}) => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [machine, setMachine] = useState(null)
    const [selectedRows, setSelectedRows] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [extraFilters, setExtraFilters] = useState({
        year: `${new Date().getFullYear()}`,
        month: `${new Date().getMonth() + 1}`,
    })
    const [modalSettings, setModalSettings] = useState(InitialModalSettings)
    const [showMultiModal, setShowMultiModal] = useState(false)

    const [savePlan] = useMutation(CreatePlan)
    const [updatePlan] = useMutation(UpdatePlan)

    useEffect(() => {
        setMachine(data)
        setSelectedRow(null)
        setSelectedRows(null)

        setExtraFilters({
            ...extraFilters,
            machine: data.CODE,
        })
    }, [data])

    const handleRowClick = (rowData) => {
        setSelectedRows(Array.isArray(rowData) ? rowData : [rowData])
    }

    const handleRowDoubleClick = (row) => {
        setModalSettings(row?.PLANNING_ID ? {
            ...modalSettings,
            DATE: row.DATE,
            COMMENT: row.COMMENT,
        } : InitialModalSettings)

        setSelectedRow(row)
    }

    const handleModalClose = () => {
        setSelectedRow(null)
        setShowMultiModal(false)
    }

    const setModalField = (field, value) => {
        setModalSettings({ ...modalSettings, [field]: value })
    }

    const handleSavePlan = async () => {
        if (selectedRow) {
            const { data } = selectedRow?.PLANNING_ID ?
                await updatePlan({
                    variables: {
                        id: parseInt(selectedRow.PLANNING_ID, 10),
                        data: {
                            ...modalSettings,
                            PROD_ID: selectedRow?.ISBUFFER ? undefined : selectedRow.ID,
                            BUFFER_ID: selectedRow?.ISBUFFER ? selectedRow.ID : undefined,
                        },
                    },
                })
                :
                await savePlan({
                    variables: {
                        data: {
                            ...modalSettings,
                            MACHINE_ID: machine.ID,
                            PROD_ID: selectedRow?.ISBUFFER ? undefined : selectedRow.ID,
                            BUFFER_ID: selectedRow?.ISBUFFER ? selectedRow.ID : undefined,
                        },
                    },
                })

            if (!data || data.createPlan !== 'Success' && data.updatePlan !== 'Success') return

            handleModalClose()
            tableRef?.current?.refresh()
        }

        if (selectedRows?.length) {
            await Promise.all(selectedRows.map(async (row) => {
                const { data } = row?.PLANNING_ID ?
                    await updatePlan({
                        variables: {
                            id: parseInt(row.PLANNING_ID, 10),
                            data: {
                                ...modalSettings,
                                PROD_ID: row?.ISBUFFER ? undefined : row.ID,
                                BUFFER_ID: row?.ISBUFFER ? row.ID : undefined,
                            },
                        },
                    })
                    :
                    await savePlan({
                        variables: {
                            data: {
                                ...modalSettings,
                                MACHINE_ID: machine.ID,
                                PROD_ID: row?.ISBUFFER ? undefined : row.ID,
                                BUFFER_ID: row?.ISBUFFER ? row.ID : undefined,
                            },
                        },
                    })

                if (!data || data.createPlan !== 'Success' && data.updatePlan !== 'Success') return

            }))

            handleModalClose()
            tableRef?.current?.refresh()
        }
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions'>
                <Button
                    label={'Salvesta'}
                    onClick={handleSavePlan}
                />
            </div>
        )
    }

    const handleModalChange = () => {
        tableRef.current?.refresh()
    }

    return (
        <div className='planning-machine'>
            <div className='planning-machine-header'>
                <h4>{`${machine?.LABEL} (${t(machine?.CODE)})`}</h4>
                <div className='planning-machine-header--location'>{machine?.location?.LABEL}</div>
                <div className='planning-machine-header--size'>
                    <p>{`Min. mõõt: ${machine?.MIN_SIZE || ''}`}</p>
                    <p>{`Max. mõõt: ${machine?.MAX_SIZE || ''}`}</p>
                </div>
                <div className='planning-machine-header--performance'>{`Tootlikkus tunnis: ${machine?.PERFORMANCE || ''}`}</div>
            </div>

            <div className='planning-machine-actions'>
                <Button
                    label={'Määra kuupäev'}
                    onClick={() => setShowMultiModal(true)}
                    disabled={!selectedRows?.length}
                />
            </div>

            <PlanTable
                ref={tableRef}
                machineId={machine?.ID}
                machineMaxVolume={machine?.PERFORMANCE * 8}
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}
                subscriptions={subscriptions}
                activeRows={selectedRows}
                allowSelect
            />

            <PlanningItemModal
                show={!!selectedRow}
                planningItem={selectedRow}
                machine={machine}
                close={handleModalClose}
                onChange={handleModalChange}
            />

            <Modal
                show={showMultiModal}
                close={handleModalClose}
                renderActions={renderModalActions}
            >
                <DateInput
                    label={t('Kuupäev')}
                    onChange={(val) => setModalField('DATE', val)}
                    value={modalSettings?.DATE ? new Date(modalSettings.DATE) : undefined}
                />
            </Modal>
        </div>
    )
}

export default PlanningMachine