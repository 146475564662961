import gql from 'graphql-tag';

export const GetOrders = gql`
  query getAllOrders ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput]) {
    getAllOrders (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters) {
      count
      rows {
        ID
        ORDERNO
        ODATE

        contact {
          CONTACT
        }
        creator {
          RNAME
        }
        client {
          ID
          NAME
        }
      }
    }
  }
`;

export const OrdersQuery = gql`
  query ordersQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    ordersQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count,
      rows {
        ID
        ORDERNO
        ODATE

        contact {
          CONTACT
        }
        creator {
          RNAME
        }
        client {
          ID
          NAME
        }
      }
    }
  }
`;

export const GetAllOrderRows = gql`
  query getAllOrderRows ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput]) {
    getAllOrderRows (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters) {
      count
      rows {
        ID
        BOX
        MATERIAL
        L
        B
        H
        H_EXTRA
        AMOUNT
        FPRICE
        PRINT
        ORDER_ID

        order {
          ORDERNO
          ODATE
          CREATOR

          client {
            NAME
          }
        }
      }
    }
  }
`;

export const SearchOrders = gql`
  query searchOrders ($searchInput: String!) {
    searchOrders (searchInput: $searchInput) {
      count
      rows {
        ID
        ORDERNO
        ODATE

        contact {
          CONTACT
        }
        creator {
          RNAME
        }
        client {
          ID
          NAME
        }
      }
    }
  }
`;

export const MaterialOrderRowsQuery = gql`
  query materialOrderRowsQuery ($from: String, $to: String, $factory: String, $orderBy: String, $sort: String, $filters: [FiltersInput], $page: Int!, $limit: Int!) {
    materialOrderRowsQuery (from: $from, to: $to, factory: $factory, orderBy: $orderBy, sort: $sort, filters: $filters, page: $page, limit: $limit) {
      count,
      rows {
        ID
        DELIVERY_DATE
        SIZE
        NUUT
        MARK
        FSC
        AMOUNT
        arrived
        inTransit

        factoryOrder {
          COMPLETED
        }

        materialOrder {
          ID
          ORDER_NUMBER
          CREATED_DATE

          factory {
            NAME
          }
        }

        production {
          BOX
          MOOT
          DEADLINE

          client {
            NAME
          }
        }

        buffer {
          BOX
          MOOT
          DEADLINE

          client {
            NAME
          }
        }
      }
    }
  }
`;

export const BufferQuery = gql`
  query bufferQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    bufferQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        NAME
        TELLIMUSNO
        KLIENT_BOXCODE
        START_DATE
        NEW_DEADLINE
        LAMINAATOR
        ALIIM
        _3LIIM
        KLIIM
        SLOTTER
        INLINE
        SRE
        TIGEL
        FLEXO
        SLITTER
        TOOTLEMINE
        FIN_DATE
        SHIPPED
        ALUSEID
        RKOGUS
        ALUS_MOOT
        KOMMENTAAR
        BOX
        KOGUS
        TRYKK
        MAT_ORDER_ID
        MOOT
        MATERIAL
        PRINTOUT
        KLIENT_ORDER
        PROD_COMMENT
        STOCK_MAT
        STOCK_MOOT
        MAT_LOADED
        CREATOR
        KLIENT_ID
        TRANSPORDIFIRMA
        ADDRESS
        OFSET
        CONTACT_ID
        EKSTSEN
        PREMAID
        PRODUCTION_CODE
        VERONA
        TITAN
        EXPORTED
        STANTS
        KLISHEE
        TRANSPORT
        PALLET
        PARTIAL_AMOUNT
        AFD
        FPR
        FACTORY_NAME
        MAT_AMOUNT
        MAT_ORDERS_CNT
        SIZE
        FSC
        MAT_NUUT
        MAT_DELIVERY
        ARRIVED_AMOUNT
        DIFF_AMOUNT
        HALDURNAME
        HALDUREMAIL
        HALDUREMAIL_CC
        HALDURPHONE
        HALDURFAX
        SISESTAJA_EMAIL
        SISESTAJA_NIMI
        HINNAPAKKUMINE
        ETTEMAKS
        DEPT
        MAKSEAEG
        TRANSPORT_DATE
        PRICE
        KLIENT_INFORMED
        CLIENTEMAIL
        CLIENTPHONE
        CLIENTADDRESS
        VAT
        TYPE
        REGNO
        ETTEMAKS_DONE
        BLUEPRINT_ID
      }
    }
  }
`;

export const ProductionsQuery = gql`
  query productionsQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    productionsQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        NAME
        TELLIMUSNO
        KLIENT_BOXCODE
        START_DATE
        NEW_DEADLINE
        LAMINAATOR
        ALIIM
        _3LIIM
        KLIIM
        SLOTTER
        INLINE
        SRE
        TIGEL
        FLEXO
        SLITTER
        TOOTLEMINE
        FIN_DATE
        SHIPPED
        ALUSEID
        RKOGUS
        ALUS_MOOT
        KOMMENTAAR
        BOX
        KOGUS
        TRYKK
        MAT_ORDER_ID
        MOOT
        MATERIAL
        PRINTOUT
        KLIENT_ORDER
        PROD_COMMENT
        STOCK_MAT
        STOCK_MOOT
        MAT_LOADED
        CREATOR
        KLIENT_ID
        TRANSPORDIFIRMA
        ADDRESS
        OFSET
        CONTACT_ID
        EKSTSEN
        PREMAID
        PRODUCTION_CODE
        VERONA
        TITAN
        EXPORTED
        STANTS
        KLISHEE
        TRANSPORT
        PALLET
        PARTIAL_AMOUNT
        AFD
        FPR
        FACTORY_NAME
        MAT_AMOUNT
        MAT_ORDERS_CNT
        SIZE
        FSC
        MAT_NUUT
        MAT_DELIVERY
        ARRIVED_AMOUNT
        DIFF_AMOUNT
        HALDURNAME
        HALDUREMAIL
        HALDUREMAIL_CC
        HALDURPHONE
        HALDURFAX
        SISESTAJA_EMAIL
        SISESTAJA_NIMI
        HINNAPAKKUMINE
        ETTEMAKS
        DEPT
        MAKSEAEG
        TRANSPORT_DATE
        PRICE
        KLIENT_INFORMED
        CLIENTEMAIL
        CLIENTPHONE
        CLIENTADDRESS
        VAT
        TYPE
        REGNO
        ETTEMAKS_DONE
        BLUEPRINT_ID
      }
    }
  }
`;

export const GetAllUsers = gql`
  query getAllUsers ($page: Int!, $limit: Int!) {
    getAllUsers (page: $page, limit: $limit) {
      count
      rows {
        ID
        USERCODE
        UNAME
        RNAME
        PROFESSION
        PHONE
        FAX
        EMAIL
        SUPERUSER
        EXCEL
        MATERIAL
        CC_EMAIL
        CONSTRUCTOR
        MAT_ORDERS
        DIRECTO
      }
    }
  }
`;

export const GetProductions = gql`
  query getProductions ($page: Int!, $limit: Int!, $latest: Boolean) {
    getProductions (page: $page, limit: $limit, latest: $latest) {
      count
      rows {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        KLIENT_ORDER
        KLIENT_ID
        START_DATE
        FIN_DATE
        SHIPPED
        TRANSPORT_DATE
        NEW_DEADLINE
        PRODUCTION_CODE
        BOX
        MOOT
        MATERIAL
        KLIENT_BOXCODE
        KOGUS
        PARTIAL_AMOUNT
        TELLIMUSNO
        TRANSPORDIFIRMA
        RKOGUS
        PRICE
        ALUSEID
        KLISHEE
        STANTS
        TRANSPORT
        PALLET
        ADDRESS
        KOMMENTAAR

        creator {
          ID
          RNAME
        }

        blueprint {
          ID
          STOCK_MOOT
          STOCK_AMOUNT
          NUUT
        }

        client {
          ID
          NAME
          REGNO
          EMAIL
          PHONE
          ETTEMAKS
          ADDRESS
          MAKSEAEG
          TRANSFIRMA
          DEPT

          manager {
            ID
            RNAME
          }
        }
      }
    }
  }
`;

export const GetBuffers = gql`
  query getBuffers ($page: Int!, $limit: Int!, $latest: Boolean) {
    getBuffers (page: $page, limit: $limit, latest: $latest) {
      count
      rows {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        KLIENT_ORDER
        KLIENT_ID
        START_DATE
        FIN_DATE
        SHIPPED
        TRANSPORT_DATE
        NEW_DEADLINE
        PRODUCTION_CODE
        BOX
        MOOT
        MATERIAL
        KLIENT_BOXCODE
        KOGUS
        PARTIAL_AMOUNT
        TELLIMUSNO
        TRANSPORDIFIRMA
        RKOGUS
        PRICE
        ALUSEID
        KLISHEE
        STANTS
        TRANSPORT
        PALLET
        ADDRESS
        KOMMENTAAR

        creator {
          ID
          RNAME
        }

        client {
          ID
          NAME
          REGNO
          EMAIL
          PHONE
          ETTEMAKS
          ADDRESS
          MAKSEAEG
          TRANSFIRMA
          DEPT

          manager {
            ID
            RNAME
          }
        }

        blueprint {
          ID
          STOCK_MOOT
          STOCK_AMOUNT
          NUUT
        }
      }
    }
  }
`;

export const GetMaterialOrders = gql`
  query getMaterialOrders ($page: Int!, $limit: Int!) {
    getMaterialOrders (page: $page, limit: $limit) {
      count
      rows {
        ID
        CREATED_DATE
        ORDERNO
        REMARK
        ORDERED
        ORDER_NUMBER
        orderRows {
          ID
          ORDER_ID
          SIZE
          SIZE_BOX
          MARK
          MARK_COMMENT
          AMOUNT
          DELIVERY_DATE
          NUUT
          REMARK
          PRICE
          CLIENT_ID
          BOX_DATE
          SPEED
          BOX_SIZE
          BOX_TYPE
          PROD_ID
          FINISHED_DATE
          PRIORITY
        }
        factory {
          ID
          NAME
          ADDRESS
          EMAIL
          CONTACT
        }
        creator {
          RNAME
        }
      }
    }
  }
`;

export const GetFactoryMaterialOrders = gql`
  query getFactoryMaterialOrders ($prodId: Int, $bufferId: Int) {
    getFactoryMaterialOrders (prodId: $prodId, bufferId: $bufferId) {
      ID
      ORDER_ID
      SIZE
      SIZE_BOX
      MARK
      MARK_COMMENT
      AMOUNT
      DELIVERY_DATE
      NUUT
      REMARK
      PRICE
      CLIENT_ID
      BOX_DATE
      SPEED
      BOX_SIZE
      BOX_TYPE
      PROD_ID
      FINISHED_DATE
      PRIORITY

      materialOrder {

        paperFactory {
          FACTORY_NAME
        }
      }

      factoryOrder {
        ID
        PROD_ID
        MAT_ORDER_NO
        MAT_ORDER_ROW_ID
        FACTORY_ID
        ARRIVAL
        COMPLETED
        STATUS
        PLANNED
        SHIPPED
        ORDERED_AMOUNT
        TRANSPORT_SIZE
        REAL_AMOUNT
        PLATES
        SIZE
        MARK
        FACTORY_CODE
        FACTORY_ORDER
        PRIORITY
        COMMENT
        BOX_SIZE
        STATUS_CHANGE
        MAT_ORDER_NUMBER
      }
    }
  }
`;

export const MaterialOrdersQuery = gql`
  query materialOrdersQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    materialOrdersQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        CREATED_DATE
        ORDERNO
        REMARK
        ORDERED
        ORDER_NUMBER

        orderRows {
          ID
          ORDER_ID
          SIZE
          SIZE_BOX
          MARK
          MARK_COMMENT
          AMOUNT
          DELIVERY_DATE
          NUUT
          REMARK
          PRICE
          CLIENT_ID
          BOX_DATE
          SPEED
          BOX_SIZE
          BOX_TYPE
          PROD_ID
          FINISHED_DATE
          PRIORITY
          production {
            ORDERNO
            BOX
            MOOT
            DEADLINE
            client {
              NAME
            }
          }
        }
        factory {
          ID
          NAME
          ADDRESS
          EMAIL
          CONTACT
        }
        paperFactory {
          ID
          FACTORY_NAME
          ADDRESS
          EMAIL
          CONTACT
        }
        creator {
          RNAME
        }
      }
    }
  }
`;

export const MaterialDeliveriesQuery = gql`
  query materialDeliveriesQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    materialDeliveriesQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        DELIVERY_DATE
        AMOUNT
        PROD_ID
        BUFFER_ID
        INVOICE

        production {
          ID
          ORDERNO

          client {
            ID
            NAME
          }

          materialOrders {
            ID
            SIZE

            materialOrder {
              ID

              factory {
                ID
                NAME
              }

              paperFactory {
                ID
                FACTORY_NAME
              }
            }
          }
        }

        buffer {
          ID
          ORDERNO

          client {
            ID
            NAME
          }

          materialOrders {
            ID
            SIZE

            materialOrder {
              ID

              factory {
                ID
                NAME
              }

              paperFactory {
                ID
                FACTORY_NAME
              }
            }
          }
        }
      }
    }
  }
`;

export const GetMaterialOrderRow = gql`
  query getMaterialOrderRow ($id: Int!) {
    getMaterialOrderRow (id: $id) {
      ID
      ORDER_ID
      SIZE
      SIZE_BOX
      MARK
      FSC
      MARK_COMMENT
      AMOUNT
      DELIVERY_DATE
      NUUT
      REMARK
      PRICE
      CLIENT_ID
      BOX_DATE
      SPEED
      BOX_SIZE
      BOX_TYPE
      PROD_ID
      BUFFER_ID
      FINISHED_DATE
      PRIORITY
      IS_END_PRODUCT
    }
  }
`;

export const GetRelatedMaterialOrders = gql`
  query getRelatedMaterialOrders ($productionCode: String!) {
    getRelatedMaterialOrders (productionCode: $productionCode) {
      ID
      ORDER_ID
      SIZE
      SIZE_BOX
      MARK
      MARK_COMMENT
      AMOUNT
      DELIVERY_DATE
      NUUT
      REMARK
      PRICE
      CLIENT_ID
      BOX_DATE
      SPEED
      BOX_SIZE
      BOX_TYPE
      PROD_ID
      FINISHED_DATE
      PRIORITY

      production {
        ID
        ORDERNO
        ORDER_DATE
        DEADLINE
        KLIENT_ORDER
        KLIENT_ID
        START_DATE
        FIN_DATE
        SHIPPED
        TRANSPORT_DATE
        NEW_DEADLINE
        PRODUCTION_CODE
        BOX
        MOOT
        MATERIAL
        KLIENT_BOXCODE
        KOGUS
        PARTIAL_AMOUNT
        TELLIMUSNO
        TRANSPORDIFIRMA
        RKOGUS
        PRICE
        ALUSEID
        KLISHEE
        STANTS
        TRANSPORT
        PALLET
        ADDRESS
        KOMMENTAAR

        creator {
          ID
          RNAME
        }

        client {
          ID
          NAME
          REGNO
          EMAIL
          PHONE
          ETTEMAKS
          ADDRESS
          MAKSEAEG
          TRANSFIRMA
          DEPT

          manager {
            ID
            RNAME
          }
        }
      }
    }
  }
`;

export const GetMaterialOrder = gql`
  query getMaterialOrder ($id: Int!) {
    getMaterialOrder (id: $id) {
      ID
      CREATED_DATE
      ORDERNO
      REMARK
      ORDERED
      ORDER_NUMBER
      FACTORY_ID
      orderRows {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        FSC
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        BUFFER_ID
        FINISHED_DATE
        PRIORITY
        IS_END_PRODUCT
        production {
          ORDERNO
          DEADLINE
          BOX
          MOOT
          PRODUCTION_CODE
          FIN_DATE
          TRANSPORT_DATE
          SHIPPED
          NEW_DEADLINE

          client {
            NAME
          }
        }
        buffer {
          ORDERNO
          DEADLINE
          BOX
          MOOT
          PRODUCTION_CODE
          FIN_DATE
          TRANSPORT_DATE
          SHIPPED
          NEW_DEADLINE

          client {
            NAME
          }
        }
      }
      factory {
        ID
        NAME
        ADDRESS
        EMAIL
        CONTACT
      }
      paperFactory {
        ID
        FACTORY_NAME
        ADDRESS
        EMAIL
        CONTACT
      }
      creator {
        RNAME
      }
    }
  }
`;

export const GetOrderByNumber = gql`
  query getOrderByNumber ($orderNumber: Int!) {
    getOrderByNumber (orderNumber: $orderNumber) {
      ID
      ORDERNO
      ODATE

      contact {
        CONTACT
      }
      creator {
        RNAME
      }
      client {
        ID
        NAME
      }
      orderRows {
        ID
        ORDER_ID
        BOX
        MATERIAL
        MAT_PRICE
        L
        B
        H
        PRINT
        AMOUNT
        AL
        AB
        FPRICE
        PROFIT
        LPRICE
        BOXNAME
        MAT_L
        MAT_B
        NUUT1
        NUUT2
        NUUT3
        BOXES
        LISTS
        COLOR1
        COLOR2
        COLOR3
        COLOR4
        PANTONE1
        PANTONE2
        PANTONE3
        PANTONE4
        MATRIITS
        KLISHEE
        COMMENT
        PICTURE
        ROTATS
        STATUS
        PROD_DATE
        FILE1
        FILE2
        NUUT4
        NUUT5
        TRANSPORT
        H_EXTRA
        FACTORY
        SLITTER
        FLEXO
        TIGEL
        SRE
        INLINE
        SLOTTER
        KLIIM
        ALIIM
        _3LIIM
        LAMINAATOR
        TOOTLEMINE
        NUUDID
        PROD_CODE
        PRODUCTION_CODE
        MAT_TYPE
        CONSTR_NAME
        CONSTR_DATE
        VERONA
        TITAN
        CREATION_YEAR
        PER_SHEET
        RECEIVER
        LP_L
        LP_B
        TT_L
        TT_B
        CLASSTYPE
        DENSITY
        AFD
        FPR
        EKSTSEN
        PREMAID
      }
    }
  }
`;

export const GetOrder = gql`
  query getOrder ($id: Int!) {
    getOrder (id: $id) {
      ID
      ORDERNO
      ODATE
      CREATOR
      TEXT
      PROD_DATE
      CONTACT_ID
      CLIENT_ID

      contact {
        ID
        CONTACT
        PHONE
        EMAIL
        SKYPE
        MOBILE
      }
      creator {
        ID
        UNAME
        RNAME
        EMAIL
        PHONE
        FAX
        PROFESSION
      }
      client {
        ID
        NAME
      }
      orderRows {
        ID
        ORDER_ID
        BOX
        MATERIAL
        MAT_PRICE
        FSC
        L
        B
        H
        PRINT
        AMOUNT
        AL
        AB
        FPRICE
        PROFIT
        LPRICE
        BOXNAME
        MAT_L
        MAT_B
        NUUT1
        NUUT2
        NUUT3
        BOXES
        LISTS
        COLOR1
        COLOR2
        COLOR3
        COLOR4
        PANTONE1
        PANTONE2
        PANTONE3
        PANTONE4
        MATRIITS
        KLISHEE
        COMMENT
        PICTURE
        ROTATS
        STATUS
        PROD_DATE
        FILE1
        FILE2
        NUUT4
        NUUT5
        TRANSPORT
        H_EXTRA
        FACTORY
        SLITTER
        FLEXO
        TIGEL
        SRE
        INLINE
        SLOTTER
        KLIIM
        ALIIM
        _3LIIM
        LAMINAATOR
        TOOTLEMINE
        NUUDID
        PROD_CODE
        PRODUCTION_CODE
        MAT_TYPE
        CONSTR_NAME
        CONSTR_DATE
        VERONA
        TITAN
        CREATION_YEAR
        PER_SHEET
        RECEIVER
        LP_L
        LP_B
        TT_L
        TT_B
        CLASSTYPE
        DENSITY
        AFD
        FPR
        EKSTSEN
        PREMAID
        materialItem {
          MARK
          MARK2
        }
      }
    }
  }
`;

export const GetBuffer = gql`
  query getBuffer ($id: Int!) {
    getBuffer (id: $id) {
      ID
      ORDERNO
      KLIENT_ID
      STAATUS
      ORDER_ID
      ORDER_ROW_ID
      START_DATE
      FIN_DATE
      DEADLINE
      SHIPPED
      KLIENT_ORDER
      KLIENT_BOXCODE
      ALUSEID
      TRANSPORDIFIRMA
      ADDRESS
      KOMMENTAAR
      ETTEMAKS
      CREATOR
      BOX
      FSC
      MATERIAL
      PRICE
      KOGUS
      CREATED
      TRYKK
      MOOT
      ORDER_DATE
      TRANSPORT_DATE
      RKOGUS
      TRANSPORT_ID
      ALUS_MOOT
      CONTACT_ID
      TELLIMUSNO
      FACTORY
      KLIENT_INFORMED
      PRINTOUT
      NEW_DEADLINE
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      PROD_COMMENT
      STOCK_MAT
      STOCK_MOOT
      MAT_LOADED
      TOOTLEMINE
      OFSET
      EKSTSEN
      PREMAID
      PROD_CODE
      PRODUCTION_CODE
      VERONA
      TITAN
      EXPORTED
      PROD_READY
      STANTS
      KLISHEE
      TRANSPORT
      PARTIAL_AMOUNT
      CLASS
      AFD
      FPR
      PALLET
      STOCK_AMOUNT

      order {
        ID
        ORDERNO
      }

      materialOrders {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        FINISHED_DATE
        PRIORITY
      }

      materialDeliveries {
        ID
        PROD_ID
        INVOICE
        DELIVERY_DATE
        AMOUNT
      }

      creator {
        ID
        RNAME
      }

      contact {
        ID
        CONTACT
        EMAIL
        PHONE
      }

      client {
        ID
        NAME
        REGNO
        EMAIL
        PHONE
        ETTEMAKS
        ADDRESS
        MAKSEAEG
        TRANSFIRMA
        DEPT
        VAT

        manager {
          ID
          RNAME
          UNAME
        }

        contacts {
          ID
          CONTACT
          EMAIL
          PHONE
        }
        
        transports {
          ID
          TRANSPORT
          OLD
          MAIN
        }
      }

      emails {
        ID
        EMAIL
        KUUP
        USER
        TEXT
      }
    }
  }
`;

export const GetProduction = gql`
  query getProduction ($id: Int!) {
    getProduction (id: $id) {
      ID
      ORDERNO
      KLIENT_ID
      STAATUS
      ORDER_ID
      ORDER_ROW_ID
      START_DATE
      FIN_DATE
      DEADLINE
      SHIPPED
      KLIENT_ORDER
      KLIENT_BOXCODE
      ALUSEID
      TRANSPORDIFIRMA
      ADDRESS
      KOMMENTAAR
      ETTEMAKS
      CREATOR
      BOX
      FSC
      MATERIAL
      PRICE
      KOGUS
      CREATED
      TRYKK
      MOOT
      ORDER_DATE
      TRANSPORT_DATE
      RKOGUS
      TRANSPORT_ID
      ALUS_MOOT
      CONTACT_ID
      TELLIMUSNO
      FACTORY
      KLIENT_INFORMED
      PRINTOUT
      NEW_DEADLINE
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      PROD_COMMENT
      STOCK_MAT
      STOCK_MOOT
      MAT_LOADED
      TOOTLEMINE
      OFSET
      EKSTSEN
      PREMAID
      PROD_CODE
      PRODUCTION_CODE
      VERONA
      TITAN
      EXPORTED
      PROD_READY
      STANTS
      KLISHEE
      TRANSPORT
      PARTIAL_AMOUNT
      DELIVERY_COMMENT
      CLASS
      AFD
      FPR
      PALLET
      STOCK_AMOUNT

      order {
        ID
        ORDERNO
      }

      materialOrders {
        ID
        ORDER_ID
        SIZE
        SIZE_BOX
        MARK
        MARK_COMMENT
        AMOUNT
        DELIVERY_DATE
        NUUT
        REMARK
        PRICE
        CLIENT_ID
        BOX_DATE
        SPEED
        BOX_SIZE
        BOX_TYPE
        PROD_ID
        FINISHED_DATE
        PRIORITY
      }

      materialDeliveries {
        ID
        PROD_ID
        INVOICE
        DELIVERY_DATE
        AMOUNT
      }

      creator {
        ID
        RNAME
        USERCODE
      }

      contact {
        ID
        CONTACT
        EMAIL
        PHONE
      }

      client {
        ID
        NAME
        REGNO
        EMAIL
        PHONE
        ETTEMAKS
        ADDRESS
        MAKSEAEG
        TRANSFIRMA
        DEPT
        VAT

        manager {
          ID
          RNAME
          UNAME
        }

        contacts {
          ID
          CONTACT
          EMAIL
          PHONE
        }
        
        transports {
          ID
          TRANSPORT
          OLD
          MAIN
        }
      }

      emails {
        ID
        EMAIL
        KUUP
        USER
        TEXT
      }
    }
  }
`;

export const GetOrderRowByName = gql`
  query getOrderRowByName ($input: String!, $clientId: Int!, $rowId: Int!) {
    getOrderRowByName (input: $input, clientId: $clientId, rowId: $rowId) {
      ID
      ORDER_ID
      BOX
      MATERIAL
      MAT_PRICE
      L
      B
      H
      PRINT
      AMOUNT
      AL
      AB
      FPRICE
      PROFIT
      LPRICE
      BOXNAME
      MAT_L
      MAT_B
      NUUT1
      NUUT2
      NUUT3
      BOXES
      LISTS
      COLOR1
      COLOR2
      COLOR3
      COLOR4
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      MATRIITS
      KLISHEE
      COMMENT
      PICTURE
      ROTATS
      STATUS
      PROD_DATE
      FILE1
      FILE2
      NUUT4
      NUUT5
      TRANSPORT
      H_EXTRA
      FACTORY
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      TOOTLEMINE
      NUUDID
      PROD_CODE
      PRODUCTION_CODE
      MAT_TYPE
      CONSTR_NAME
      CONSTR_DATE
      VERONA
      TITAN
      CREATION_YEAR
      PER_SHEET
      RECEIVER
      LP_L
      LP_B
      TT_L
      TT_B
      CLASSTYPE
      DENSITY
      AFD
      FPR
      EKSTSEN
      PREMAID
    }
  }
`;

export const GetOrderRow = gql`
  query getOrderRow ($id: Int!) {
    getOrderRow (id: $id) {
      ID
      ORDER_ID
      BOX
      MATERIAL
      FSC
      MAT_PRICE
      L
      B
      H
      PRINT
      AMOUNT
      AL
      AB
      FPRICE
      PROFIT
      LPRICE
      BOXNAME
      MAT_L
      MAT_B
      NUUT1
      NUUT2
      NUUT3
      BOXES
      LISTS
      COLOR1
      COLOR2
      COLOR3
      COLOR4
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      MATRIITS
      KLISHEE
      COMMENT
      PICTURE
      ROTATS
      STATUS
      PROD_DATE
      FILE1
      FILE2
      NUUT4
      NUUT5
      TRANSPORT
      H_EXTRA
      FACTORY
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      TOOTLEMINE
      NUUDID
      PROD_CODE
      PRODUCTION_CODE
      MAT_TYPE
      CONSTR_NAME
      CONSTR_DATE
      VERONA
      TITAN
      CREATION_YEAR
      PER_SHEET
      RECEIVER
      LP_L
      LP_B
      TT_L
      TT_B
      CLASSTYPE
      DENSITY
      AFD
      FPR
      EKSTSEN
      PREMAID

      production {
        ID
        TRANSPORDIFIRMA
        TRANSPORT
        ADDRESS
        KLIENT_ORDER
        KLIENT_BOXCODE
        DEADLINE
        CREATED

        contact {
          EMAIL
        }
      }

      box {
        NAME
        FORMULA_L
        FORMULA_B
      }

      order {
        ID
        ORDERNO
        ODATE

        creator {
          RNAME
        }

        client {
          ID
          NAME
          EMAIL
          ADDRESS

          manager {
            RNAME
          }
        }
      }
    }
  }
`;

export const GetClasses = gql`
  query getClasses {
    getClasses {
      ID
      NAME
    }
  }
`;

export const GetBoxes = gql`
  query getBoxes {
    getBoxes {
      ID
      NAME
      FORMULA_L
      FORMULA_B
    }
  }
`;

export const GetFactories = gql`
  query getFactories {
    getFactories {
      ID
      NAME
      ADDRESS
      EMAIL
      CONTACT
    }
  }
`;

export const GetPaperFactories = gql`
  query getPaperFactories {
    getPaperFactories {
      ID
      FACTORY_NAME
      ADDRESS
      EMAIL
      CONTACT
      DIRECTO_CODE
    }
  }
`;

export const GetClients = gql`
  query getClients {
    getClients {
      ID
      NAME
      ADDRESS
      EMAIL
      CONTACT
    }
  }
`;

export const GetClientsByName = gql`
  query getClientsByName ($name: String) {
    getClientsByName (name: $name) {
      ID
      NAME
    }
  }
`;

export const GetClient = gql`
  query getClient ($id: Int!) {
    getClient (id: $id) {
      ID
      NAME
      ADDRESS
      CONTACT
      PHONE
      FAX
      EMAIL
      UNAME
      SKYPE
      ETTEMAKS
      COMMON
      DEPT
      TRANSPORT
      TRANSFIRMA
      VAT
      MANAGER
      ACTIVE
      MAKSEAEG
      REGNO
      MANAGER_ID
      CHANGE
      COUNTRY
      PREPAID
      DIRECTO_CODE
    }
  }
`;

export const GetClientTransports = gql`
  query getClientTransports ($clientId: Int!) {
    getClientTransports (clientId: $clientId) {
      ID
      TRANSPORT
      OLD
      MAIN
    }
  }
`;

export const GetAllClients = gql`
  query getAllClients ($page: Int!, $limit: Int!) {
    getAllClients (page: $page, limit: $limit) {
      count
      rows {
        ID
        NAME
        ADDRESS
        CONTACT
        PHONE
        FAX
        EMAIL
        UNAME
        SKYPE
        ETTEMAKS
        COMMON
        DEPT
        TRANSPORT
        TRANSFIRMA
        VAT
        MANAGER
        ACTIVE
        MAKSEAEG
        REGNO
        MANAGER_ID
        CHANGE
        COUNTRY
        PREPAID
        DIRECTO_CODE
        manager {
          RNAME
        }
      }
    }
  }
`;

export const SearchClients = gql`
  query searchClients ($searchInput: String!) {
    searchClients (searchInput: $searchInput) {
      ID
      NAME
      ADDRESS
      EMAIL
      CONTACT
      contacts {
        ID
        CONTACT
        PHONE
        EMAIL
        MOBILE
      }

      manager {
        ID
        RNAME
      }
      
      transports {
        ID
        TRANSPORT
        OLD
        MAIN
      }
    }
  }
`;

export const SearchClientsAll = gql`
  query searchClients ($searchInput: String!) {
    searchClients (searchInput: $searchInput) {
      ID
      NAME
      ADDRESS
      CONTACT
      PHONE
      FAX
      EMAIL
      UNAME
      SKYPE
      ETTEMAKS
      COMMON
      DEPT
      TRANSPORT
      TRANSFIRMA
      VAT
      MANAGER
      ACTIVE
      MAKSEAEG
      REGNO
      MANAGER_ID
      CHANGE
      COUNTRY
      PREPAID
      manager {
        RNAME
      }
    }
  }
`;

export const GetClientContacts = gql`
  query getClientContacts ($clientId: Int!) {
    getClientContacts (clientId: $clientId) {
      CONTACT
      ID
      EMAIL
      PHONE
      MOBILE
      FAX
      SKYPE
      OLD
    }
  }
`;

export const GetProfile = gql`
  query getProfile {
    getProfile {
      ID
      RNAME
      UNAME
      EMAIL
      PASSWORD_UPDATED
    }
  }
`;

export const GetUsers = gql`
  query getUsers {
    getUsers {
      ID
      RNAME
      UNAME
      USERCODE
      EMAIL
    }
  }
`;

export const GetMaterials = gql`
  query getMaterials {
    getMaterials {
      FACTORY
      MARK
      MARK2
      PRICE
      LAYER
      ACTIVE
      TYPE
      CODE
      DENSITY
      FSC
    }
  }
`;

export const GetMaterialCodes = gql`
  query getMaterialCodes ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    getMaterialCodes (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        CODE
        CLASS
        COLOR
        NAME
        NUUT
        MARK
        UNIT
        FSC
        CREATED_AT
      }
    }
  }
`;

export const GetAllMaterials = gql`
  query getAllMaterials ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    getAllMaterials (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        FACTORY
        MARK
        MARK2
        PRICE
        LAYER
        ACTIVE
        TYPE
        CODE
        DENSITY
        FSC
      }
    }
  }
`;

export const GetMaterialDeliveries = gql`
  query getMaterialDeliveries ($prodId: Int, $bufferId: Int) {
    getMaterialDeliveries (prodId: $prodId, bufferId: $bufferId) {
      ID
      PROD_ID
      INVOICE
      DELIVERY_DATE
      AMOUNT
    }
  }
`;

export const GetInvoices = gql`
  query getInvoices ($page: Int!, $limit: Int!) {
    getInvoices (page: $page, limit: $limit) {
      count
      rows {
        ID
        CREATED_DATE
        CLIENT_ID
        PALLETS
        PLACES
        NETTO
        GROSS
        PAYMENT_DATE
        CREATOR
        ORDERNO
        GOODS
        COMMENT
        COUNTRY
        PREPAID_AMOUNT
        client {
          NAME
          ID
          VAT
        }
        author {
          RNAME
          ID
          UNAME
        }
      }
    }
  }
`;

export const GetInvoice = gql`
  query getInvoice ($id: Int!) {
    getInvoice (id: $id) {
      ID
      CREATED_DATE
      CLIENT_ID
      PALLETS
      PLACES
      NETTO
      GROSS
      PAYMENT_DATE
      CREATOR
      ORDERNO
      GOODS
      COMMENT
      COUNTRY
      PREPAID_AMOUNT
      client {
        NAME
        ID
        VAT
      }
      author {
        RNAME
        ID
        UNAME
      }
    }
  }
`;

export const GetInvoiceRows = gql`
  query getInvoiceRows ($invoiceId: Int!) {
    getInvoiceRows (invoiceId: $invoiceId) {
      ID
      INVOICE_ID
      PRODUCT_ID
      production {
        ID
        ORDERNO
        KLIENT_ORDER
        KLIENT_BOXCODE
        RKOGUS
        MOOT
        PRICE
        ALUSEID
        BOX
        ADDRESS
        FSC
      }
    }
  }
`;

export const GetInvoiceExtraRows = gql`
  query getInvoiceExtraRows ($invoiceId: Int!) {
    getInvoiceExtraRows (invoiceId: $invoiceId) {
      ID
      PRICE
      AMOUNT
      INVOICE_ID
      PRODUCT_ID
      product {
        ID
        PRODUCT
        PRICE
        CODE
      }
    }
  }
`;

export const DeliveryNotesQuery = gql`
  query deliveryNotesQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
    deliveryNotesQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        CREATED_DATE
        CLIENT_ID
        CREATOR
        ORDERNO
        PALLETS
        PALLET_COSTS
        BOOKKEEPING
        COMMENT
        client {
          NAME
          ID
          VAT
          ADDRESS
        }
        author {
          RNAME
          ID
          UNAME
        }
      }
    }
  }
`;

export const GetDeliveryNotes = gql`
  query getDeliveryNotes ($page: Int!, $limit: Int!) {
    getDeliveryNotes (page: $page, limit: $limit) {
      count
      rows {
        ID
        CREATED_DATE
        CLIENT_ID
        CREATOR
        ORDERNO
        PALLETS
        PALLET_COSTS
        BOOKKEEPING
        COMMENT
        client {
          NAME
          ID
          VAT
          ADDRESS
        }
        author {
          RNAME
          ID
          UNAME
        }
      }
    }
  }
`;

export const GetDeliveryNotesByProd = gql`
  query getDeliveryNotesByProd ($prodId: Int!) {
    getDeliveryNotesByProd (prodId: $prodId) {
      ID
      CREATED_DATE
      CLIENT_ID
      CREATOR
      ORDERNO
      PALLETS
      PALLET_COSTS
      BOOKKEEPING
      COMMENT
      client {
        NAME
        ID
        VAT
        ADDRESS
      }
      author {
        RNAME
        ID
        UNAME
      }
      rows {
        ID
        AMOUNT
      }
    }
  }
`;

export const GetDeliveryNote = gql`
  query getDeliveryNote ($id: Int!) {
    getDeliveryNote (id: $id) {
      ID
      CREATED_DATE
      CLIENT_ID
      CREATOR
      ORDERNO
      PALLETS
      PALLET_COSTS
      BOOKKEEPING
      COMMENT
      client {
        NAME
        ID
        VAT
        ADDRESS
      }
      author {
        RNAME
        ID
        UNAME
      }
    }
  }
`;

export const GetDeliveryNoteWithRows = gql`
  query getDeliveryNoteWithRows ($id: Int!) {
    getDeliveryNoteWithRows (id: $id) {
      ID
      CREATED_DATE
      CLIENT_ID
      CREATOR
      ORDERNO
      PALLETS
      PALLET_COSTS
      BOOKKEEPING
      COMMENT
      client {
        NAME
        ID
        VAT
        ADDRESS
      }
      author {
        RNAME
        ID
        UNAME
      }
      rows {
        ID
        INVOICE_ID
        PRODUCT_ID
        AMOUNT
        production {
          ID
          ORDERNO
          KLIENT_ORDER
          KLIENT_BOXCODE
          RKOGUS
          MOOT
          PRICE
          FSC
          ALUSEID
          BOX
          ADDRESS
          PALLET
          TRANSPORT
          STANTS
          KLISHEE
        }
      }
    }
  }
`;

export const GetDeliveryNoteRows = gql`
  query getDeliveryNoteRows ($noteId: Int!) {
    getDeliveryNoteRows (noteId: $noteId) {
      ID
      INVOICE_ID
      PRODUCT_ID
      AMOUNT
      production {
        ID
        ORDERNO
        KLIENT_ORDER
        KLIENT_BOXCODE
        RKOGUS
        MOOT
        PRICE
        ALUSEID
        FSC
        BOX
        ADDRESS
        PALLET
        TRANSPORT
        STANTS
        KLISHEE
      }
    }
  }
`;

export const GetConstants = gql`
  query getConstants {
    getConstants {
      ID
      PRINTPRICE
      FAILURE
      DSTART
      DEND
      COLOR1
      COLOR2
      ROTAT
      KOEF
      ORDERNO
      PRODNO
      OFFSET
      INVOICENO
      PALLETPRICE
      MATERIAL_ORDERNO
      DNS
      SMTP_SERVER
      EMAIL
      TEMPLATE
      TELLIMUSNO
      XXX_TIMER
      INVOICENO_DOM
      PALLETPRICE_DOM
      PREPAID_INVOICE
      CREDIT_NOTE
      SSL_TYPE
      PORT
      DIRECTO
      BUFFERNO
      BUFFERTELLIMUSNO
      BUFFERISSUENO
      MATERIALCODENO
    }
  }
`

export const GetExtraProducts = gql`
  query getExtraProducts {
    getExtraProducts {
      ID
      PRODUCT
      PRICE
      CODE
    }
  }
`

export const GetMaterialsByFactory = gql`
  query getMaterialsByFactory ($factory: String!) {
    getMaterialsByFactory (factory: $factory) {
      FACTORY
      MARK
      MARK2
      PRICE
      LAYER
      ACTIVE
      TYPE
      CODE
      DENSITY
    }
  }
`;

export const GetPrintouts = gql`
  query getPrintouts ($prodId: Int, $bufferId: Int) {
    getPrintouts (prodId: $prodId, bufferId: $bufferId) {
      id
      prod_id
      buffer_id
      bottom
      height
      count
      items
    }
  }
`;

export const GetPallets = gql`
  query getPallets {
    getPallets {
      id
      name
    }
  }
`;

export const SearchMaterialSizes = gql`
query searchMaterialSizes ($searchInput: String!, $box: Boolean) {
  searchMaterialSizes (searchInput: $searchInput, box: $box)
}
`;

export const SearchMaterialNuut = gql`
query searchMaterialNuut ($searchInput: String!) {
  searchMaterialNuut (searchInput: $searchInput)
}
`;

export const SearchMaterial = gql`
query searchMaterial ($searchInput: String!) {
  searchMaterial (searchInput: $searchInput) {
    MARK
    FSC
    DENSITY
  }
}
`;

export const GetFileTypes = gql`
query getFileTypes {
  getFileTypes {
    ID
    NAME
  }
}
`;

export const GetFiles = gql`
query getFiles ($orderRowId: Int!) {
  getFiles (orderRowId: $orderRowId) {
    ID
    FILENAME
    fileType {
      ID
      NAME
    }
    user {
      ID
      RNAME
    }
  }
}
`;

export const SearchAllMaterial = gql`
query searchMaterial ($searchInput: String!) {
  searchMaterial (searchInput: $searchInput) {
    MARK
    FACTORY
    MARK2
    PRICE
    LAYER
    ACTIVE
    TYPE
    CODE
    DENSITY
  }
}
`;

export const SearchProduction = gql`
query searchProduction ($searchInput: String!) {
  searchProduction (searchInput: $searchInput) {
    ID
    ORDERNO
    ORDER_DATE
    DEADLINE
    KLIENT_ORDER
    KLIENT_ID
    START_DATE
    FIN_DATE
    SHIPPED
    TRANSPORT_DATE
    NEW_DEADLINE
    PRODUCTION_CODE
    BOX
    MOOT
    MATERIAL
    KLIENT_BOXCODE
    KOGUS
    PARTIAL_AMOUNT
    TELLIMUSNO
    TRANSPORDIFIRMA
    RKOGUS
    PRICE
    ALUSEID
    KLISHEE
    STANTS
    TRANSPORT
    PALLET
    ADDRESS
    KOMMENTAAR

    creator {
      ID
      RNAME
    }

    client {
      ID
      NAME
      REGNO
      EMAIL
      PHONE
      ETTEMAKS
      ADDRESS
      MAKSEAEG
      TRANSFIRMA
      DEPT

      manager {
        ID
        RNAME
      }
    }
  }
}
`;

export const GetBasicReport = gql`
query getBasicReport ($start: Date!, $end: Date!, $period: String!, $shipped: Boolean) {
  getBasicReport (start: $start, end: $end, period: $period, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetQuotationsReport = gql`
query getQuotationsReport ($start: Date!, $end: Date!, $period: String!) {
  getQuotationsReport (start: $start, end: $end, period: $period) {
    value
    label
  }
}
`;

export const GetYearlyReport = gql`
query getYearlyReport ($start: Date!, $end: Date!, $shipped: Boolean, $type: String) {
  getYearlyReport (start: $start, end: $end, shipped: $shipped, type: $type) {
    value
    month
    year
  }
}
`;

export const GetSalesReport = gql`
query getSalesReport ($start: Date!, $end: Date!, $shipped: Boolean) {
  getSalesReport (start: $start, end: $end, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetQuotationsUserReport = gql`
query getQuotationsUserReport ($start: Date!, $end: Date!) {
  getQuotationsUserReport (start: $start, end: $end) {
    value
    label
  }
}
`;

export const GetCompanyReport = gql`
query getCompanyReport ($start: Date!, $end: Date!, $shipped: Boolean) {
  getCompanyReport (start: $start, end: $end, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetSingleCompanyReport = gql`
query getSingleCompanyReport ($start: Date!, $end: Date!, $period: String!, $client: Int!, $shipped: Boolean) {
  getSingleCompanyReport (start: $start, end: $end, period: $period, client: $client, shipped: $shipped) {
    value
    label
  }
}
`;

export const GetLostClients = gql`
query getLostClients ($date: Date!) {
  getLostClients (date: $date) {
    value
    label
  }
}
`;

export const GetUserRealisation = gql`
query getUserRealisation ($start: Date!, $end: Date!, $period: String!, $shipped: Boolean) {
  getUserRealisation (start: $start, end: $end, period: $period, shipped: $shipped) {
    value
    period
    name
  }
}
`;

export const ExportMaterials = gql`
  query exportMaterials {
    exportMaterials
  }
`;

export const GetLocations = gql`
query getLocations ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
  getLocations (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        LABEL
      }
    }
  }
`;

export const GetMachines = gql`
query getMachines ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
  getMachines (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        LABEL
        PERFORMANCE
        CODE
        MIN_SIZE
        MAX_SIZE
        ORDER_NO
        LOCATION_ID

        location {
          ID
          LABEL
        }
      }
    }
  }
`;

export const PlanningQuery = gql`
  query planningQuery ($machineId: Int!) {
    planningQuery (machineId: $machineId) {
      DATE
      ITEMS {
        PLANNING_ID
        LOADED
        ISBUFFER
        HALTED
        HALTED_REASON
        OPERATOR_COMMENT
        STARTED
        COMPLETED
        ID
        MACHINE_ID
        PROD_ID
        COMMENT
        DATE
        ID
        ORDER
        ORDERNO
        ORDER_DATE
        DEADLINE
        NAME
        TELLIMUSNO
        KLIENT_BOXCODE
        START_DATE
        NEW_DEADLINE
        LAMINAATOR
        ALIIM
        _3LIIM
        KLIIM
        SLOTTER
        INLINE
        SRE
        TIGEL
        FLEXO
        SLITTER
        TOOTLEMINE
        FIN_DATE
        SHIPPED
        ALUSEID
        RKOGUS
        ALUS_MOOT
        KOMMENTAAR
        BOX
        KOGUS
        TRYKK
        MAT_ORDER_ID
        MOOT
        MATERIAL
        PRINTOUT
        KLIENT_ORDER
        PROD_COMMENT
        STOCK_MAT
        STOCK_MOOT
        MAT_LOADED
        CREATOR
        KLIENT_ID
        TRANSPORDIFIRMA
        ADDRESS
        OFSET
        CONTACT_ID
        EKSTSEN
        PREMAID
        PRODUCTION_CODE
        VERONA
        TITAN
        EXPORTED
        STANTS
        KLISHEE
        TRANSPORT
        PALLET
        PARTIAL_AMOUNT
        AFD
        FPR
        FACTORY_NAME
        MAT_AMOUNT
        MAT_ORDERS_CNT
        SIZE
        FSC
        MAT_NUUT
        MAT_DELIVERY
        ARRIVED_AMOUNT
        DIFF_AMOUNT
        HALDURNAME
        HALDUREMAIL
        HALDUREMAIL_CC
        HALDURPHONE
        HALDURFAX
        SISESTAJA_EMAIL
        SISESTAJA_NIMI
        HINNAPAKKUMINE
        ETTEMAKS
        DEPT
        MAKSEAEG
        TRANSPORT_DATE
        PRICE
        KLIENT_INFORMED
        CLIENTEMAIL
        CLIENTPHONE
        CLIENTADDRESS
        VAT
        TYPE
        REGNO
        ETTEMAKS_DONE
        ORDER_ROW_ID
      }
    }
  }
`;

export const GetPlannedWork = gql`
  query getPlannedWork ($machineId: Int!) {
    getPlannedWork (machineId: $machineId) {
      ID
      DATE
      MACHINE_ID
      PROD_ID
      BUFFER_ID
      PRODUCT_IN
      PRODUCT_OUT
      PRODUCT_WASTE
      OPERATOR_COMMENT
      COMMENT
      HALTED
      STARTED
      COMPLETED
      HALTED_REASON
      CONFIGURATION_STARTED

      production {
        ID
        ORDERNO
        KLIENT_ID
        STAATUS
        ORDER_ID
        ORDER_ROW_ID
        START_DATE
        FIN_DATE
        DEADLINE
        SHIPPED
        KLIENT_ORDER
        KLIENT_BOXCODE
        ALUSEID
        TRANSPORDIFIRMA
        ADDRESS
        KOMMENTAAR
        ETTEMAKS
        CREATOR
        BOX
        FSC
        MATERIAL
        PRICE
        KOGUS
        CREATED
        TRYKK
        MOOT
        ORDER_DATE
        TRANSPORT_DATE
        RKOGUS
        TRANSPORT_ID
        ALUS_MOOT
        CONTACT_ID
        TELLIMUSNO
        FACTORY
        KLIENT_INFORMED
        PRINTOUT
        NEW_DEADLINE
        SLITTER
        FLEXO
        TIGEL
        SRE
        INLINE
        SLOTTER
        KLIIM
        ALIIM
        _3LIIM
        LAMINAATOR
        PROD_COMMENT
        STOCK_MAT
        STOCK_MOOT
        MAT_LOADED
        TOOTLEMINE
        OFSET
        EKSTSEN
        PREMAID
        PROD_CODE
        PRODUCTION_CODE
        VERONA
        TITAN
        EXPORTED
        PROD_READY
        STANTS
        KLISHEE
        TRANSPORT
        PARTIAL_AMOUNT
        CLASS
        AFD
        FPR
        PALLET
      }

      buffer {
        ID
        ORDERNO
        KLIENT_ID
        STAATUS
        ORDER_ID
        ORDER_ROW_ID
        START_DATE
        FIN_DATE
        DEADLINE
        SHIPPED
        KLIENT_ORDER
        KLIENT_BOXCODE
        ALUSEID
        TRANSPORDIFIRMA
        ADDRESS
        KOMMENTAAR
        ETTEMAKS
        CREATOR
        BOX
        FSC
        MATERIAL
        PRICE
        KOGUS
        CREATED
        TRYKK
        MOOT
        ORDER_DATE
        TRANSPORT_DATE
        RKOGUS
        TRANSPORT_ID
        ALUS_MOOT
        CONTACT_ID
        TELLIMUSNO
        FACTORY
        KLIENT_INFORMED
        PRINTOUT
        NEW_DEADLINE
        SLITTER
        FLEXO
        TIGEL
        SRE
        INLINE
        SLOTTER
        KLIIM
        ALIIM
        _3LIIM
        LAMINAATOR
        PROD_COMMENT
        STOCK_MAT
        STOCK_MOOT
        MAT_LOADED
        TOOTLEMINE
        OFSET
        EKSTSEN
        PREMAID
        PROD_CODE
        PRODUCTION_CODE
        VERONA
        TITAN
        EXPORTED
        PROD_READY
        STANTS
        KLISHEE
        TRANSPORT
        PARTIAL_AMOUNT
        CLASS
        AFD
        FPR
        PALLET
      }
    }
  }
`


export const GetRelatedPlans = gql`
  query getRelatedPlans ($planId: Int!) {
    getRelatedPlans (planId: $planId) {
      ID
      MACHINE_ID
      COMPLETED
      STARTED
      HALTED
      DATE
      HALTED_REASON
      HALTED_COMMENT
      PRODUCT_IN
      PRODUCT_OUT
      PRODUCT_WASTE
      ORDER
      CONFIGURATION_STARTED

      machine {
        ORDER_NO
        CODE
        LABEL
      }
    }
  }
`

export const GetPdfImage = gql`
  query getPdfImage ($uri: String!) {
    getPdfImage (uri: $uri)
  }
`

export const GetBlueprint = gql`
  query getBlueprint ($productionCode: String!) {
    getBlueprint (productionCode: $productionCode) {
      ID
      COMMENT
      CREATOR
      CONSTRUCTOR
      DESIGNER
      CREATED
      PCS_PER_HIT
      PCS_PER_STOCK
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      STOCK_AMOUNT
      STOCK_MOOT
      OFFSET_AMOUNT
      OFFSET_MOOT
      TOOTLEMINE
      EKSTSEN
      PREMAID
      PRODUCTION_CODE
      VERONA
      TITAN
      AFD
      FPR
      KLISHEE
      MATRIITS
      MATRIITS_LOCATION
      KLISHEE_LOCATION
      TT
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      PACKAGING_METHOD
      AMOUNT_PALLET
      SLOTS
      FILE
      FLUTE
      PRINTING
      FEEDING
      NUUT
      PALLET_LAYOUT
      PALLET_TYPE
      
      designer {
        ID
        RNAME
      }

      constructorUser {
        ID
        RNAME
      }
    }
  }
`

export const GetSimilarBlueprint = gql`
  query getSimilarBlueprint ($orderRowId: Int!) {
    getSimilarBlueprint (orderRowId: $orderRowId) {
      ID
      COMMENT
      CREATOR
      CONSTRUCTOR
      DESIGNER
      CREATED
      PCS_PER_HIT
      PCS_PER_STOCK
      SLITTER
      FLEXO
      TIGEL
      SRE
      INLINE
      SLOTTER
      KLIIM
      ALIIM
      _3LIIM
      LAMINAATOR
      STOCK_AMOUNT
      STOCK_MOOT
      OFFSET_AMOUNT
      OFFSET_MOOT
      TOOTLEMINE
      EKSTSEN
      PREMAID
      PRODUCTION_CODE
      VERONA
      TITAN
      AFD
      FPR
      KLISHEE
      MATRIITS
      MATRIITS_LOCATION
      KLISHEE_LOCATION
      TT
      PANTONE1
      PANTONE2
      PANTONE3
      PANTONE4
      PACKAGING_METHOD
      AMOUNT_PALLET
      SLOTS
      FILE
      FLUTE
      PRINTING
      FEEDING
      NUUT
      PALLET_LAYOUT
      PALLET_TYPE
      
      designer {
        ID
        RNAME
      }

      constructorUser {
        ID
        RNAME
      }
    }
  }
`

export const GetBlueprintLog = gql`
  query getBlueprintLog ($productionCode: String!) {
    getBlueprintLog (productionCode: $productionCode) {
      ID
      CREATOR
      CREATED
      UPDATED_FIELDS
      author {
        RNAME
      }
    }
  }
`

export const FactoryOrdersQuery = gql`
query factoryOrdersQuery ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $filters: [FiltersInput], $extraFilters: FilterInput) {
  factoryOrdersQuery (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, filters: $filters, extraFilters: $extraFilters) {
      count
      rows {
        ID
        DELIVERY_DATE
        SIZE
        SIZE_BOX
        AMOUNT
        NUUT
        MARK
        DELIVERED_AMOUNT
        CLIENT

        factory {
          FACTORY_NAME
        }

        factoryOrder {
          ID
          COMPLETED
          ARRIVAL
        }

        materialOrder {
          ORDER_NUMBER
        }

        buffer {
          ORDERNO
          ID
          MOOT
        }
        production {
          ORDERNO
          ID
          MOOT
        }
      }
    }
  }
`;

export const GetConstructors = gql`
  query getConstructors {
    getConstructors {
      ID
      RNAME
      UNAME
    }
  }
`

export const GetMaterialCode = gql`
  query getMaterialCode ($input: MaterialCodeInput!) {
    getMaterialCode (input: $input)
  }
`