import { useMutation, useQuery } from '@apollo/client'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { PlanningQuery } from '../graphql/queries'
import { formatDate } from '../util/format'
import { useTranslation } from 'react-i18next'
import PlanTableRow from './PlanTableRow'
import { UpdatePlan } from '../graphql/mutations'

const getStatusColor = (row) => {
    if (row.LOADED) return '#5e155e'
    if (row.ARRIVED_AMOUNT > 0) return 'rgb(185 146 41)'
    if (row.FIN_DATE) return '#01a045'
    if (row.HALTED) return 'rgb(233 142 118)'
    if (row.STARTED) return 'rgb(116 157 193)'
    return 'transparent'
}

const PlanTable = forwardRef(({
    machineId,
    machineMaxVolume,
    includeFields,
    onRowClick,
    onRowDoubleClick,
    fieldConditions,
    subscriptions,
    allowSelect,
    activeRows,
}, ref) => {

    const { t } = useTranslation()
    const [items, setItems] = useState([])
    const { data, refetch, subscribeToMore, loading } = useQuery(PlanningQuery, {
        notifyOnNetworkStatusChange: true,
        skip: !machineId,
        fetchPolicy: 'cache-and-network',
        variables: {
            machineId: parseInt(machineId, 10),
        },
    })
    const [updatePlan] = useMutation(UpdatePlan)
    const [selectedRow, setSelectedRow] = useState(null)

    useEffect(() => {
        if (loading) return

        setItems(data?.planningQuery || [])
    }, [data, loading])

    useEffect(() => {
        const unsubscribe = []

        if (subscriptions?.length > 0) {
            subscriptions.forEach(({ document, updateQuery }) => {
                const unsub = subscribeToMore({
                    document,
                    updateQuery,
                })
                unsubscribe.push(unsub)
            })
        }

        return () => {
            unsubscribe.forEach(unsub => {
                unsub()
            })
        }
    }, [])

    useImperativeHandle(ref, () => ({
        refresh: () => {
            refetch()
        },
    }))

    const getColValue = (row, field) => {
        const conditionResult = fieldConditions ? fieldConditions(row, field) : null
        return conditionResult ? conditionResult : (typeof row[field] === 'object' ? '' : row[field])
    }

    const updateRowOrdering = async (rows) => {
        if (!rows?.length) return

        Promise.all(rows.map(async (row) => {
            await updatePlan({
                variables: {
                    id: parseInt(row.PLANNING_ID, 10),
                    data: {
                        ORDER: parseInt(row.ORDER, 10),
                    },
                },
            })
        }))
    }

    const handleOrderChange = (prevIndex, nextIndex, group) => {
        let newItems = [...items]

        const groupIndex = newItems.findIndex((item) => item.DATE === group)
        let itemList = [...newItems[groupIndex].ITEMS]
        itemList.splice(nextIndex, 0, itemList.splice(prevIndex, 1)[0])

        const reorderedList = itemList.map((row, index) => ({
            ...row,
            ORDER: index,
        }))

        newItems[groupIndex] = {
            DATE: group,
            ITEMS: reorderedList,
        }

        setItems(newItems)

        updateRowOrdering(reorderedList)
    }

    const getVolumeClass = (itemList) => {
        let dateVolume = itemList.reduce((acc, item) => acc += item.KOGUS, 0)

        // dateVolume += 100000

        return dateVolume > machineMaxVolume ? ' plan-table-volume-warning' : ''
    }

    const handleRowClick = (row, e, index, itemsIndex) => {
        if (!onRowClick) return

        if (allowSelect && e.shiftKey && selectedRow) {
            const first = items[itemsIndex].ITEMS.findIndex(i => i.ID === selectedRow.ID)
            const currentIndex = index
            let selectedItems = []

            if (first > currentIndex) selectedItems = items[itemsIndex].ITEMS.filter((x, i) => i <= first && i >= currentIndex)
            if (first < currentIndex) selectedItems = items[itemsIndex].ITEMS.filter((x, i) => i >= first && i <= currentIndex)

            onRowClick(selectedItems)
            return
        }

        if (allowSelect && (e.ctrlKey || e.metaKey) && activeRows?.length > 0) {
            onRowClick([row, ...activeRows])
            return
        }
        setSelectedRow(row)
        onRowClick(row)
    }

    if (!items?.length) return <></>

    return (
        <div className='plan-table'>
            <div className='plan-table-wrapper'>
            <table id="plan-table">
                <thead>
                    <tr>
                        {
                            includeFields.map((header, index) =>
                                <th key={`plan-table-header-${header.value}`}>
                                    <span>{t(header.label)}</span>
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        items.filter(i => i.DATE).map((item, itemsIndex) =>
                            <React.Fragment key={`plan-table-date-${item.DATE || 'unplanned'}`}>
                                <tr>
                                    <td className={`plan-table-date${item?.DATE ? getVolumeClass(item.ITEMS) : ''}`} colSpan={includeFields.length}>{!item?.DATE ? 'Planeerimata' : formatDate(new Date(item.DATE))}</td>
                                </tr>
                                {
                                    item.ITEMS.map((row, index) =>
                                        <PlanTableRow
                                            key={row.ID}
                                            index={index}
                                            rowData={row}
                                            group={item.DATE}
                                            includeFields={includeFields}
                                            getStatusColor={getStatusColor}
                                            onRowClick={(rowData, e) => handleRowClick(rowData, e, index, itemsIndex)}
                                            onRowDoubleClick={onRowDoubleClick}
                                            getColValue={getColValue}
                                            onOrderChange={handleOrderChange}
                                            isActive={activeRows?.some(i => i.ID === row.ID)}
                                        />
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </tbody>
            </table>
            </div>
            <div className='plan-table-wrapper'>
            <table id="plan-table-unplanned">
                <thead>
                    <tr>
                        {
                            includeFields.map((header, index) =>
                                <th key={`plan-table-unplanned-header-${header.value}`}>
                                    <span>{t(header.label)}</span>
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        items.filter(i => !i.DATE).map((item, itemsIndex) =>
                            <React.Fragment key={`plan-table-date-${item.DATE || 'unplanned'}`}>
                                <tr>
                                    <td className={`plan-table-date${item?.DATE ? getVolumeClass(item.ITEMS) : ''}`} colSpan={includeFields.length}>{!item?.DATE ? 'Planeerimata' : formatDate(new Date(item.DATE))}</td>
                                </tr>
                                {
                                    item.ITEMS.map((row, index) =>
                                        <PlanTableRow
                                            key={row.ID}
                                            index={index}
                                            rowData={row}
                                            group={item.DATE}
                                            includeFields={includeFields}
                                            getStatusColor={getStatusColor}
                                            onRowClick={(rowData, e) => handleRowClick(rowData, e, index, itemsIndex)}
                                            onRowDoubleClick={onRowDoubleClick}
                                            getColValue={getColValue}
                                            onOrderChange={handleOrderChange}
                                            isActive={activeRows?.some(i => i.ID === row.ID)}
                                        />
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </tbody>
            </table>
            </div>
        </div>
    )
})

export default PlanTable