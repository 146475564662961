import React, { useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const PlanTableRow = ({
    rowData,
    index,
    includeFields,
    getStatusColor,
    onRowClick,
    onRowDoubleClick,
    getColValue,
    onOrderChange,
    group,
    isActive,
}) => {

    const ref = useRef()

    const [{ opacity }, drag] = useDrag(() => ({
        type: 'TableRow',
        item: {
            index,
            group,
        },
        collect: (monitor) => ({
          opacity: monitor.isDragging() ? 0.3 : 1
        })
    }), [index])

    const [{ isOver }, drop] = useDrop(
        () => ({
          accept: 'TableRow',
          drop: (item) => {
            if (item.group !== group || index === item.index) return

            if (onOrderChange) onOrderChange(item.index, index, group)
          },
          collect: (monitor) => ({
            isOver: !!monitor.isOver()
          }),
        }),
        [index]
    )

    if (rowData?.PLANNING_ID) {
        drop(drag(ref))
    }

    return (
        <tr ref={ref} className={`plan-table-row${isOver ? ` plan-table-row--over` : ''}${isActive ? ' plan-table-row--selected' : ''}`}>
            {
                includeFields.map((field, fieldIndex) =>
                    <td
                        key={`filter-table-row-${index}-col-${fieldIndex}`}
                        className='plan-table--item'
                        style={{
                            backgroundColor: getStatusColor(rowData),
                        }}
                        onClick={(e) => {
                            if (onRowClick) onRowClick(rowData, e)
                        }}
                        onDoubleClick={() => {
                            if (onRowDoubleClick) onRowDoubleClick(rowData)
                        }}
                    >
                        { getColValue(rowData, field.value) }
                    </td>
                )
            }
        </tr>
    )
}

export default PlanTableRow