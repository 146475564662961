import { BrowserRouter } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import Routing from './Routing'
import { Suspense } from 'react'
import { NotificationProvider } from './providers/Notification'
import ActivityIndicator from './components/ActivityIndicator'
import AppWrapper from './AppWrapper'
import { ProvideConstants } from './providers/Constants'

const renderFallback = () => (
  <div className='loading-view'>
    <ActivityIndicator />
  </div>
)

const App = () => {
  return (
    <Suspense fallback={renderFallback()}>
      <DndProvider backend={HTML5Backend}>
        <NotificationProvider>
          <BrowserRouter>
            <AppWrapper>
              <ProvideConstants>
                <Routing />
              </ProvideConstants>
            </AppWrapper>
          </BrowserRouter>
        </NotificationProvider>
      </DndProvider>
    </Suspense>
  )
}

export default App
