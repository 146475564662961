import React, { useEffect, useState } from 'react'
import Modal from './Modal'
import DateInput from './DateInput'
import Textarea from './Textarea'
import BlueprintModal from './BlueprintModal'
import { useMutation } from '@apollo/client'
import Button from './Button'
import Checkbox from './Checkbox'
import { CreatePlan, RemovePlan, UpdatePlan } from '../graphql/mutations'
import { useTranslation } from 'react-i18next'
import PlanningStatus from './PlanningStatus'
import { useNotification } from '../providers/Notification'

const InitialFields = {
    DATE: '',
    LOADED: false,
    COMMENT: '',
}

const PlanningItemModal = ({
    planningItem,
    machine,
    show,
    close,
    onChange,
}) => {

    const { t } = useTranslation()
    const { dispatch } = useNotification()
    const [fields, setFields] = useState(InitialFields)
    const [removePlan] = useMutation(RemovePlan)
    const [savePlan] = useMutation(CreatePlan)
    const [updatePlan] = useMutation(UpdatePlan)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setFields({
            ...fields,
            DATE: planningItem?.DATE,
            COMMENT: planningItem?.COMMENT,
            LOADED: planningItem?.LOADED,
        })

        return () => {
            setFields(InitialFields)
        }
    }, [planningItem])

    const setField = (field, value) => {
        setFields({ ...fields, [field]: value })
    }

    const handleRemovePlan = async () => {
        const { data } = await removePlan({
            variables: {
                id: parseInt(planningItem.PLANNING_ID, 10),
            },
        })

        if (!data || data.removePlan !== 'Success') return

        if (onChange) onChange()
        if (close) close()
    }

    const handleSavePlan = async () => {
        setLoading(true)

        try {
            const { data } = planningItem?.PLANNING_ID ?
                await updatePlan({
                    variables: {
                        id: parseInt(planningItem.PLANNING_ID, 10),
                        data: {
                            ...fields,
                            PROD_ID: planningItem?.ISBUFFER ? undefined : planningItem.ID,
                            BUFFER_ID: planningItem?.ISBUFFER ? planningItem.ID : undefined,
                        },
                    },
                })
                :
                await savePlan({
                    variables: {
                        data: {
                            ...fields,
                            MACHINE_ID: machine.ID,
                            PROD_ID: planningItem?.ISBUFFER ? undefined : planningItem.ID,
                            BUFFER_ID: planningItem?.ISBUFFER ? planningItem.ID : undefined,
                        },
                    },
                })

            if (!data || data.createPlan !== 'Success' && data.updatePlan !== 'Success') return

            if (onChange) onChange()
        } catch (e) {
            dispatch({
                type: 'ADD',
                payload: {
                    type: 'error',
                    content: 'Salvestamine ebaõnnestus. Kontrolli väljasid'
                },
            })
        } finally {
            setLoading(false)
        }
    }

    const renderModalActions = () => {
        return (
            <div className='modal-actions'>
                {
                    planningItem?.PLANNING_ID ?
                        <Button
                            label={'Eemalda'}
                            className={'btn-cancel'}
                            onClick={handleRemovePlan}
                        />
                        :
                        <></>
                }
                <Button
                    label={'Salvesta'}
                    onClick={handleSavePlan}
                    loading={loading}
                />
            </div>
        )
    }

    return (
        <Modal
            show={show}
            close={close}
            renderActions={renderModalActions}
            className={'planning-item-modal'}
        >
            <PlanningStatus
                planId={planningItem?.PLANNING_ID}
                productionCode={planningItem?.PRODUCTION_CODE}
            />
            <DateInput
                label={t('Kuupäev')}
                onChange={(val) => setField('DATE', val)}
                value={fields?.DATE ? new Date(fields.DATE) : undefined}
            />
            <Textarea
                label={t('Kommentaar')}
                onChange={(e) => setField('COMMENT', e.target.value)}
                value={fields.COMMENT}
            />
            <Checkbox
                label={'Laetud'}
                onChange={(e) => setField('LOADED', e.target.checked)}
                value={fields?.LOADED}
            />
            {
                planningItem?.ORDER_ROW_ID && show ?
                    <BlueprintModal
                        orderRowId={planningItem?.ORDER_ROW_ID}
                        productionCode={planningItem?.PRODUCTION_CODE}
                        production={planningItem ? {
                            ...planningItem,
                            materialOrders: [{
                                MARK: planningItem?.MATERIAL,
                            }]
                        } : undefined}
                    />
                    :
                    <></>
            }
        </Modal>
    )
}

export default PlanningItemModal