import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import AppHeader from './components/AppHeader'
import { useAuth } from './providers/Auth'
import ClassesScreen from './screens/Classes/Classes'
import BoxesScreen from './screens/Boxes/Boxes'
import ClientScreen from './screens/Client/Client'
import ClientsScreen from './screens/Clients/Clients'
import DeliveryNoteScreen from './screens/DeliveryNote/DeliveryNote'
import DeliveryNotesScreen from './screens/DeliveryNotes/DeliveryNotes'
import FactoriesScreen from './screens/Factories/Factories'
import PaperFactoriesScreen from './screens/PaperFactories/PaperFactories'
import InvoiceScreen from './screens/Invoice/Invoice'
import InvoicesScreen from './screens/Invoices/Invoices'
import LoginScreen from './screens/Login/Login'
import MaterialOrderScreen from './screens/MaterialOrder/MaterialOrder'
import MaterialOrdersScreen from './screens/MaterialOrders/MaterialOrders'
import OrderScreen from './screens/Order/Order'
import OrdersScreen from './screens/Orders/Orders'
import ProductionScreen from './screens/Production/Production'
import ProductionsScreen from './screens/Productions/Productions'
import SalesScreen from './screens/Sales/Sales'
import SalesListScreen from './screens/SalesList/SalesList'
import UsersScreen from './screens/Users/Users'
import ExtraProductsScreen from './screens/ExtraProducts/ExtraProducts'
import MaterialsScreen from './screens/Materials/Materials'
import ConstantsScreen from './screens/Constants/Constants'
import QuotationSearchScreen from './screens/QuotationSearch/QuotationSearch'
import PdfViewerScreen from './screens/PdfViewer/PdfViewer'
import MaterialReportScreen from './screens/MaterialReport/MaterialReport'
import ReportBasicScreen from './screens/ReportBasic/ReportBasic'
import ReportYearScreen from './screens/ReportYear/ReportYear'
import ReportSalesScreen from './screens/ReportSales/ReportSales'
import ReportCompanyScreen from './screens/ReportCompany/ReportCompany'
import ReportQuotationsScreen from './screens/ReportQuotations/ReportQuotations'
import ReportQuotationsUserScreen from './screens/ReportQuotationsUser/ReportQuotationsUser'
import ReportOneCompanyScreen from './screens/ReportOneCompany/ReportOneCompany'
import LostClientsScreen from './screens/LostClients/LostClients'
import ReportUserScreen from './screens/ReportUser/ReportUser'
import BufferScreen from './screens/Buffer'
import BuffersScreen from './screens/Buffers'
import MaterialDeliveriesScreen from './screens/MaterialDeliveries'
import LocationsScreen from './screens/Locations'
import MachinesScreen from './screens/Machines'
import PlanningScreen from './screens/Planning'
import OperatorScreen from './screens/Operator'
import MaterialCodesScreen from './screens/MaterialCodes'

const pageData = {
    'orders': {
        title: 'Hinnapakkumised',
    },
    'order': {
        title: 'Hinnapakkumise andmed',
    },
    'buffers': {
        title: 'Buffer ladu',
    },
    'buffer': {
        title: 'Buffer andmed',
    },
    'productions': {
        title: 'Tootmine',
    },
    'production': {
        title: 'Tootmise andmed',
    },
    'sales': {
        title: 'Müük',
    },
    'sale': {
        title: 'Müügi andmed',
    },
    'material-orders': {
        title: 'Materjali tellimused',
    },
    'material-deliveries': {
        title: 'Materjali saabumised',
    },
    'material-order': {
        title: 'Materjali tellimuse andmed',
    },
    'deliverynotes': {
        title: 'Saatelehed',
    },
    'deliverynote': {
        title: 'Saatelehe andmed',
    },
    'invoices': {
        title: 'Arved',
    },
    'invoice': {
        title: 'Arve andmed',
    },
    'material-report': {
        title: 'Laekumiste aruanne',
    },
    'users': {
        title: 'Kasutajad',
    },
    'clients': {
        title: 'Kliendid',
    },
    'client': {
        title: 'Kliendi andmed',
    },
    'factories': {
        title: 'Tehased',
    },
    'boxes': {
        title: 'Karbid',
    },
    'classes': {
        title: 'Klassid',
    },
    'extra-products': {
        title: 'Lisatooted',
    },
    'materials': {
        title: 'Materjalid',
    },
    'constants': {
        title: 'Parameetrid',
    },
    'login': {
        title: 'Multipakend',
    },
}

const Routing = () => {

    const { user } = useAuth()
    const location = useLocation()

    useEffect(() => {
        const paths = location?.pathname?.split('/')
        document.title = paths?.length < 2 || !pageData[paths[1]]?.title ? 'Multipakend' : `${pageData[paths[1]]?.title}`
    }, [location])

    return (
        user ?
        (
            <div>
                <AppHeader />
                <div className='app-content'>
                    <Routes>
                        <Route path='*' element={<OrdersScreen />} />
                        <Route path='/orders' element={<OrdersScreen />} />
                        <Route path='/order/:orderId' element={<OrderScreen />} />
                        <Route path='/buffer/:id' element={<BufferScreen />} />
                        <Route path='/buffers' element={<BuffersScreen />} />
                        <Route path='/productions' element={<ProductionsScreen />} />
                        <Route path='/production/:id' element={<ProductionScreen />} />
                        <Route path='/material-orders' element={<MaterialOrdersScreen />} />
                        <Route path='/material-deliveries' element={<MaterialDeliveriesScreen />} />
                        <Route path='/material-order/:id' element={<MaterialOrderScreen />} />
                        <Route path='/material-report' element={<MaterialReportScreen />} />
                        <Route path='/sales' element={<SalesListScreen />} />
                        <Route path='/sale/:id' element={<SalesScreen />} />
                        <Route path='/invoices' element={<InvoicesScreen />} />
                        <Route path='/invoice/:id' element={<InvoiceScreen />} />
                        <Route path='/deliverynotes' element={<DeliveryNotesScreen />} />
                        <Route path='/deliverynote/:id' element={<DeliveryNoteScreen />} />
                        <Route path='/users' element={<UsersScreen />} />
                        <Route path='/factories' element={<FactoriesScreen />} />
                        <Route path='/paper-factories' element={<PaperFactoriesScreen />} />
                        <Route path='/boxes' element={<BoxesScreen />} />
                        <Route path='/classes' element={<ClassesScreen />} />
                        <Route path='/materials' element={<MaterialsScreen />} />
                        <Route path='/constants' element={<ConstantsScreen />} />
                        <Route path='/locations' element={<LocationsScreen />} />
                        <Route path='/machines' element={<MachinesScreen />} />
                        <Route path='/extra-products' element={<ExtraProductsScreen />} />
                        <Route path='/clients' element={<ClientsScreen />} />
                        <Route path='/client/:id' element={<ClientScreen />} />
                        <Route path='/quotation-search' element={<QuotationSearchScreen />} />
                        <Route path='/pdf' element={<PdfViewerScreen />} />
                        <Route path='/lost-clients' element={<LostClientsScreen />} />
                        <Route path='/report-basic' element={<ReportBasicScreen />} />
                        <Route path='/report-one-company' element={<ReportOneCompanyScreen />} />
                        <Route path='/report-yearly' element={<ReportYearScreen />} />
                        <Route path='/report-sales' element={<ReportSalesScreen />} />
                        <Route path='/report-company' element={<ReportCompanyScreen />} />
                        <Route path='/report-quotations' element={<ReportQuotationsScreen />} />
                        <Route path='/report-user-quotations' element={<ReportQuotationsUserScreen />} />
                        <Route path='/report-user' element={<ReportUserScreen />} />
                        <Route path='/planning' element={<PlanningScreen />} />
                        <Route path='/operator' element={<OperatorScreen />} />
                        <Route path='/material-codes' element={<MaterialCodesScreen />} />
                    </Routes>
                </div>
            </div>
        )
        :
        (
            <Routes>
                <Route
                    path='*'
                    element={<LoginScreen />}
                />
            </Routes>
        )
    )
}

export default Routing