import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { GetPlannedWork } from '../graphql/queries'
import { formatDate } from '../util/format'
import { useTranslation } from 'react-i18next'
import OperatorMachineProduct from './OperatorMachineProduct'
import { PlansUpdated } from '../graphql/subscriptions'
import ActivityIndicator from './ActivityIndicator'

const OperatorMachine = ({
    machine,
}) => {

    const { t } = useTranslation()
    const { data, subscribeToMore, refetch, loading } = useQuery(GetPlannedWork, {
        skip: !machine?.ID,
        variables: {
            machineId: parseInt(machine?.ID, 10),
        },
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        const unsub = subscribeToMore({
            document: PlansUpdated,
            updateQuery: () => {
                refetch()
            },
        })

        return () => unsub()
    }, [])

    const renderDate = () => {
        return !data?.getPlannedWork?.length ?
            <></>
            :
            <div className='operator-machine-date'>
                {`Kuupäev: ${formatDate(new Date(data.getPlannedWork[0].DATE))}`}
            </div>
    }

    return (
        <div className='operator-machine'>
            <div className='operator-machine--header'>
                <div className='operator-machine--header-name'>{`Masin: ${machine.LABEL} (${t(machine.CODE)})` || ''}</div>
                <div className='operator-machine--header-date'>{renderDate()}</div>
            </div>
            {
                loading ?
                    <div className='operator-machine--loading'>
                        <ActivityIndicator />
                    </div>
                    :
                    <div className='operator-machine--products'>
                        {
                            data?.getPlannedWork?.length ?
                                data.getPlannedWork.map((row) =>
                                    <OperatorMachineProduct
                                        key={row.ID}
                                        data={row}
                                    />
                                )
                                :
                                <div className='empty'>
                                    <h3>{t('Sellel masinal puuduvad planeeritud tööd')}</h3>
                                </div>
                        }
                    </div>
            }
        </div>
    )
}

export default OperatorMachine